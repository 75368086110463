export const screenSizeMixin = {
    name: 'screenSizeMixin',
    data() {
        return {
            event: null,
            screenWidth: null,
            screenHeight: null
        }
    },
    computed: {
        $screenEvent() {
            return this.event
        },
        $screenWidth() {
            return this.screenWidth || this.getScreenWidth()
        },
        $screenHeight() {
            return this.screenHeight || this.getScreenHeight()
        },
        $isMobileScreen() {
            if(this.$screenWidth > 991){
                return false;
            }
            return true;
        },
        $isDesktopScreen() {
            if(this.$screenWidth > 991){
                return true;
            }
            return false;
        }
    },
    methods: {
        getScreenWidth() {
            return window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth
        },
        getScreenHeight() {
            return window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight
        },
        handleResize(event) {
            this.event = event
            this.screenWidth = this.getScreenWidth()
            this.screenHeight = this.getScreenHeight()
        },

        $screenDestroyListener() {
            window.removeEventListener('resize', this.handleResize)
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize)
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
