<template>
    <div class="post offers btm-menu show-bulletpoints-normal">
        <Breadcrumb :background="true" :content="breadcrumb"/>

        <div class="profile">
            <div class="container-fluid">
                <div class="profile-main">

                    <profile-image-carousel v-if="slides!==null" :slides="slides"/>

                    <div class="profile-main__info full-width">
                        <h5>{{category}}</h5>
                        <share-button v-if="!showShareBtnInTitle"/>
                        <div class="profile-main__info-title">
                            <h3>{{title}}</h3>
                            <share-button v-if="showShareBtnInTitle"/>
                        </div>


                        <div v-if="nupianInstitutionTitle" class="institution-title fake mt10">{{nupianInstitutionTitle}}</div>
                        <router-link class="institution-title" v-else-if="institutionTitle!==''" :to="'/orte/'+this.institutionId"><p class="mt10 do-not-translate">{{institutionTitle}}</p></router-link>

                        <info-block target="desktop" :content="content" :infos="infoBlockInfos"/>

                    </div>
                </div>
            </div>
        </div>

        <div class="section about">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-lg-7">
                        <div class="row">
                          <div class="col-12">
                            <div class="about-content d-lg-none">
                                <p v-if="showMoreDescription" v-html="shortDescription"></p>
                                <p v-else v-html="description"></p>
                                <button v-if="shortDescription !== null" @click="showMoreDescription = !showMoreDescription" class="btn btn-block mt10 btn-link text-primary">
                                    {{ showMoreDescription ? 'MEHR ANZEIGEN' : 'WENIGER ANZEIGEN' }}
                                </button>
                            </div>
                            <div class="about-content d-none d-lg-block" v-if="description!==null">
                                <h5 class="post-heading">Beschreibung</h5>
                                <p v-html="description"></p>
                            </div>
                        </div>

                        <info-block class="col-12 d-lg-none" target="mobile" :content="content" :infos="infoBlockInfos"/>

                        <div class="col-12" v-if="schlagworte!==null">
                            <div class="about-chips">
                                <h5 class="post-heading">Schlagworte</h5>
                                <h4 class="chip" v-for="(item, i) in schlagworte" :key="i">{{ item }}</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-lg-3 ml-auto">
                    <div class="card-simple mb20 mt40 mt-lg-0" v-if="anmeldung!==''">
                        <h5 class="card-simple-title">Anmeldung</h5>
                        <p class="text-black" v-html="anmeldung"></p>
                        <a class="btn btn-block btn-secondary mt20" target="_blank" alt="Link zur Anmeldung" v-if="linkZurAnmeldung!==''" :href="linkZurAnmeldung">Zur Anmeldung</a>
                    </div>

                    <a class="btn btn-block btn-w-icon icon-left btn-secondary" target="_blank" title="Link zur Anmeldung" :href="linkZurAnmeldung" v-if="anmeldung=='' && linkZurAnmeldung!==''">
                        Zur Anmeldung
                    </a>

                    <div class="card-simple mb20 mt20" v-if="weitereInfos!==''">
                        <h5 class="card-simple-title">Weitere Infos</h5>
                        <p class="text-black" v-html="weitereInfos"></p>
                    </div>

                    <a class="btn btn-block btn-w-icon icon-left btn-secondary" target="_blank" alt="Flyer downloaden" :href="flyerLink" v-if="flyerLink!==null">
                        <span class="material-icons-outlined" aria-hidden="true">file_download</span>
                        Flyer herunterladen
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="section pt-lg-0">
        <div class="container-fluid">
            <div class="row">

                <div class="col-12 col-lg-6" v-if="hasAddress">
                    <detail-map :content="content" :addressObj="address"></detail-map>
                </div>

                <div class="col-12 col-lg-6" v-if="content!==null && contactInfo!=null">
                    <contact-block class="mt40 mt-lg-0" :contentId="content.id" :contact="contactInfo"/>
                </div>
            </div>
        </div>
    </div>

    <collection-block v-if="content!==null" :content="content"></collection-block>

</div>
</template>

<script>
import Resource from '@/api/resource';
import { getContentsOfType } from '@/api/content';
import { getFieldValues, showLoader, hideLoader, isset, json_decode, getFlyerLink } from '@/utils/helpers';
import {getSubCategory } from '@/utils/lupe-helpers';
import { screenSizeMixin } from '@/mixins/screenSizeMixin';
const contentResource = new Resource('contents');

export default {
    name: 'AngeboteDetail',
    mixins: [screenSizeMixin],
    components: {
        ShareButton: () => import('@/components/controls/ShareButton.vue'),
        InfoBlock: () => import('@/components/controls/InfoBlock.vue'),
        DetailMap: () => import('@/components/controls/DetailMap.vue'),
        ContactBlock: () => import('@/components/controls/ContactBlock.vue'),
        MerklisteCard: () => import('@/components/cards/Merkliste.vue'),
        Breadcrumb: () => import('@/components/layout/Breadcrumb.vue'),
        CollectionBlock: () => import('@/components/controls/CollectionBlock.vue'),
        ProfileImageCarousel: () => import('@/components/controls/ProfileImageCarousel.vue'),
    },
    data() {
        return {
            content: null,
            showMoreDescription: false,
            showMoreInfo: true,
        }
    },
    watch: {
      id(newId, oldId) {
        if (newId!=oldId) {
          this.getContent();
          this.isActiveLocation=true;
          this.$scrollTo();
      }
  }
},
created() {
  this.getContent();
  this.isActiveLocation=true;
},
computed: {
    id() {
        return this.$route.params.id;
    },
    title(){
        if(this.content!=null){
          return this.content.title;
      }
      return "";
  },
  institutionTitle(){
    if(this.content!=null){
        return this.content.institution_title;
    }
    return '';
},
nupianInstitutionTitle(){
    var value = getFieldValues(this.content,'nupian_anbieter');
    return value!=null?value:'';
},
institutionId(){
    if(this.content!=null){
        return this.content.institution_id;
    }
    return '';
},
description(){
    var desc = getFieldValues(this.content,'description');
    return desc!=null?desc:'';
},
teaserImages(){
    var pictures = getFieldValues(this.content,'teaser-bild');
    if(pictures != null){
        if(Array.isArray(pictures)){
            return pictures;
        }
        else{
            return [pictures];
        }
    }
    return null;
},
slides(){
    var images = [];
    if(this.teaserImages != null){
        for(var i = 0; i < this.teaserImages.length; i++){
            var image = json_decode(this.teaserImages[i]);
            if(typeof image === "object" && image !== null){
                images.push({image:this.$backendUrl+image.path,class:'teaser-img'});
            }
            else if(image !== null){
              images.push({image:this.$backendUrl+image,class:'teaser-img'});  
          }
      }
  }
  if(images.length == 0){
    return null;
}
return images;
},
anmeldung(){
    var value = getFieldValues(this.content,'anmeldung');
    return value!=null?value:'';
},
linkZurAnmeldung(){
    var value = getFieldValues(this.content,'link_zur_anmeldung');
    return value!=null?value:'';
},
flyerLink(){
    return getFlyerLink(getFieldValues(this.content,'flyer_hochladen'),this.$backendUrl);
},
showShareBtnInTitle(){
    return this.$isMobileScreen;
},
shortDescription() {
    if (this.description.length > this.$shortDescriptionWordCount) {
        this.showMoreDescription = true;

        return this.description.substring(0, this.$shortDescriptionWordCount) + '...';
    }
    return '';
},
infoBlockInfos(){
    var object = {};
    object.address = this.address;
    object.costs = getFieldValues(this.content,'kosten');
    object.appointmentType = getFieldValues(this.content,'terminart');
    object.appointments = getFieldValues(this.content,'appointments');

    object.linkTo = {
        url: getFieldValues(this.content,'url'),
        facebook: getFieldValues(this.content,'facebook'),
        instagram: getFieldValues(this.content,'instagram'),
        twitter: getFieldValues(this.content,'twitter'),
        youtube: getFieldValues(this.content,'youtube'),
    }
    return object;
},
address(){
    var address = getFieldValues(this.content,'adresse');
    return address!==null?address:'';
},
breadcrumb() {
    if(isset(this.category) && isset(this.subCategory)){
        return "Angebote | " + this.category + " | " + this.subCategory; 
    }
    return "Angebote";
},
category(){
    var value = getFieldValues(this.content,'kategorie');
    if(value!=null){
        if(Array.isArray(value) && value.length>0){
            return value[0];
        }
        return value;
    }
    return null;
},
subCategory(){
    if(isset(this.category)){
        return getSubCategory(this.content,this.category);
    }
    return null;
},
contactInfo(){
    var contact = getFieldValues(this.content,'kontaktinformationen');
    if(contact !== null && typeof contact === 'object' && !Array.isArray(contact)){
        return contact;
    }
    return null;
},
schlagworte(){
    var value = getFieldValues(this.content,'schlagworte');
    if(value!=null){
        if(!Array.isArray(value)){
            var temp = value;
            value = [temp];
        }
        return value;
    }
    return null;
},
hasAddress(){
    if(this.content !== null && this.address !== null && this.address !=='' && this.address.latitude!==null && this.address.longitude!==null){
        return true;
    }
    return false;
},
weitereInfos(){
    var value = getFieldValues(this.content,'weitere_infos');
    return value!=null?value:'';
},
},
methods:{
 getFieldValues, 
 showLoader, 
 hideLoader, 
 getContent() {
  this.loader = this.showLoader(this.loader);
  contentResource.get(this.id)
  .then(response => {
    this.content = response.data;
})
  .finally(() => {
    this.loader = this.hideLoader(this.loader);
});
},
}
}
</script>
<style lang="scss">
@import '@/scss/_mixins.scss';
@import '@/scss/_variables.scss';

.offers{

    .vueperslides__track-inner{
        background-color: #CB0B5F;
    }

    .profile{

        .container-fluid{
            @media (max-width: 991px) {
                padding-left: 0px;
                padding-right: 0px;
                margin-left: 0px;
                margin-right: 0px;
            }

            .profile-main__info {

                ul.info.desktop{
                    margin-left: 0px;
                    padding-left: 0px;
                }

                @media (max-width: 1360px) {
                  min-width: 600px;
                  margin-right: 40px;
              }

              @media (max-width: 991px) {
                padding-right: 15px;
                padding-left: 15px;
                margin-right: auto;
                margin-left: auto;
            }

            @media (max-width: 555px) {
                min-width: auto;
            }

            .info-list {
                margin-right: 20px;
            }

            h5 {
                @media (max-width: 991px) {
                    margin-bottom: 0px;
                }
            }
        }
    }
}


.mobile--scrolling{
  margin-left: 0px;
  padding-right: 15px;
}

.institution-title{
    text-decoration: underline;
    font-size: 16px;
    color:#fff;

    &.fake{
        text-decoration: none;        
    }

    &:hover,&:hover *{
        color: #203B73 !important;
    }
}
}
</style>
